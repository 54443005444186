import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { Text, Box } from "@chakra-ui/react"

export default function Home() {
  return (
    <Layout title="Impressum" slug="imprint/">
      <Box>
        <b>Für den Inhalt dieser Seiten verantwortlich:</b>
        <br />
        <br />
        Anne Rieck
        <br />
        Karl-Pokern-Straße 30
        <br />
        12587 Berlin
        <br />
        <br />
        E-Mail: hello.annerieck@gmail.com
        <br />
        Telefonnummer: +49 152 244 411 06
        <br />
        <br />
        Berufshaftpflichtversicherung: 
        <br />
        Allianz Versicherungs-AG
        <br />
        10900 Berlin
        <br />
        <br />
        Räumlicher Geltungsbereich: Europa (geografisch)
        <br />
        <br />
        <Text>
          <b>Bildnachweis:</b>
          <br />
          <Link
            to="https://tpmi.de/"
            title="Tim Piotrowski - Motion &amp; Illustration"
          >
            Tim Piotrowski
          </Link>{" "}
          - freiberuflicher Motion Designer und Illustrator-
          <br />
        </Text>
        <br />
        <Text>
          <b>Hinweis zum Yogaunterricht:</b>
          <br />
          Jede TeilnehmerIn nimmt auf eigene Verantwortung an meinem
          Yogaunterricht teil. Mein Yogaunterricht ersetzt keine Therapie. Bei
          Beschwerden empfehle ich unbedingt einen Arzt/ PhysiotherapeutIn zu
          konsultieren, um zu erfragen welche Yogaübungen geeignet sind. Gerne
          gebe ich eine Weiterempfehlung.
        </Text>
      </Box>
      <br />
      <br />
    </Layout>
  )
}
